import { GET_PROJECTS } from 'redux/types';

const initialState = {};

const projectReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case GET_PROJECTS:
      return {
        ...state,
        ...data,
      };

    default:
      return state;
  }
};

export default projectReducer;
