import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';

import { handleLogin } from 'redux/actions/auth';

export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [state, setState] = useState({});

  const onChange = (value, name) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(handleLogin(state, () => navigate('/')));
  };

  return (
    <div>
      <Form onSubmit={onSubmit}>
        <FormGroup>
          <Label>Email</Label>
          <Input
            type='email'
            name='email'
            value={state.email}
            onChange={({ target }) =>
              setState({
                ...state,
                email: target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup>
          <Label>Password</Label>
          <Input
            type='password'
            name='password'
            value={state.password}
            onChange={({ target }) =>
              setState({
                ...state,
                password: target.value,
              })
            }
          />
        </FormGroup>

        <Button className='d-block w-100' type='submit'>
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default Login;
