const { GET_DEVICES, UPDATE_DEVICE } = require('redux/types');

const initialState = {};

const deviceReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case GET_DEVICES:
      return {
        ...state,
        ...data,
      };
    case UPDATE_DEVICE:
      return {
        ...state,
        devices: state.devices?.map((item) => (item.imei === data.imei ? data : item)),
      };

    default:
      return state;
  }
};

export default deviceReducer;
