import { io } from 'socket.io-client';

import config from 'config/config';
import { SOCKET_OFF, SOCKET_ON, UPDATE_DEVICE, UPDATE_VIEW } from 'redux/types';
import { socketEvents } from 'utils/context/socketContext';
import { actionCatchBlock } from 'utils/functions/actionCatchBlock';

let socket;

export const handleSocketOn =
  ({ imei }) =>
  (dispatch) => {
    const { server } = config[config.current_env].url;

    try {
      socket = io(server, {
        auth: {
          token: localStorage.token,
        },
      });

      socket.on(socketEvents.connect, () => {
        dispatch({
          type: SOCKET_ON,
        });
      });

      socket.on(socketEvents.disconnect, () => {
        dispatch({
          type: SOCKET_OFF,
        });
      });

      socket.on(imei, (event) => {
        dispatch({
          type: UPDATE_VIEW,
          data: event,
        });
        dispatch({
          type: UPDATE_DEVICE,
          data: event,
        });
      });
    } catch (e) {
      actionCatchBlock(e, dispatch);
    }
  };

export const handleSocketOff = () => async (dispatch) => {
  if (socket) {
    await socket.close();
    socket = null;
    dispatch({
      type: SOCKET_OFF,
    });
  }
};
