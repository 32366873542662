import { GET_USER, LOGIN, LOGOUT } from 'redux/types';
import setAuthToken from 'utils/functions/auth/setAuthToken';

const initialState = {
  isAuthenticated: false,
};

const authReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case LOGIN:
      localStorage.token = data.token;
      setAuthToken();

      return {
        ...state,
        isAuthenticated: true,
      };
    case LOGOUT:
      delete localStorage.token;

      return {
        isAuthenticated: false,
      };
    case GET_USER:
      return {
        ...state,
        ...data,
        isAuthenticated: true,
      };

    default:
      return state;
  }
};

export default authReducer;
