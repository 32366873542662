import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { Eye } from 'react-feather';

import AsyncComponent from 'utils/components/AsyncComponent';
import { handleGetDevices } from 'redux/actions/device';
import { styleConfig } from 'utils/context/styleConfig';

const Devices = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.device);

  useEffect(() => {
    dispatch(handleGetDevices());
  }, []);

  const columns = [
    {
      name: '#',
      width: '50px',
      selector: (row, index) => <span>{index + 1}</span>,
    },
    {
      id: 'imei',
      name: 'imei',
      selector: ({ imei }) => <span>{imei}</span>,
    },
    {
      id: 'email',
      name: 'email',
      selector: ({ email }) => <span>{email}</span>,
    },
    {
      id: 'status',
      name: 'status',
      selector: ({ status }) => (
        <span className={`text-uppercase text-${status ? 'success' : 'danger'}`}>
          {status ? 'online' : 'offline'}
        </span>
      ),
    },
    {
      id: 'actions',
      name: 'actions',
      width: '100px',
      selector: ({ _id }) => (
        <Link to={`/devices/${_id}`}>
          <Eye size={styleConfig.iconSize} />
        </Link>
      ),
    },
  ];

  const Component = () => (
    <Fragment>
      <h1>Devices</h1>
      <DataTable data={data.devices} columns={columns} />
    </Fragment>
  );

  return <AsyncComponent Component={Component} />;
};

export default Devices;
