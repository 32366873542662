const {
  GET_DEVICE,
  GET_COMMANDS,
  UPDATE_DEVICE,
  GET_DEVICE_CONFIG,
  CLEAR_VIEW,
} = require('redux/types');

const initialState = {};

const viewReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case GET_DEVICE:
    case GET_DEVICE_CONFIG:
      return {
        ...state,
        ...data,
      };
    case GET_COMMANDS:
      return {
        ...state,
        commands: data,
      };
    case UPDATE_DEVICE:
      return {
        ...state,
        ...data,
      };
    case CLEAR_VIEW:
      return {};

    default:
      return state;
  }
};

export default viewReducer;
