import CreateProjectModal from 'components/Project/CreateProjectModal';
import ProjectCard from 'components/Project/ProjectCard';
import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';

import { handleGetProjects } from 'redux/actions/project';
import { SHOW_MODAL } from 'redux/types';

const Projects = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.project);

  useEffect(() => {
    dispatch(handleGetProjects());
  }, []);

  return (
    <Fragment>
      <CreateProjectModal />
      <div className='d-flex justify-content-between mb-2'>
        <h1>Projects</h1>
        <Button className='btn-sm btn-success' onClick={() => dispatch({ type: SHOW_MODAL })}>
          Create project
        </Button>
      </div>

      <Row>
        {data.projects?.map((item, index) => (
          <Col xs='6' className='mb-2' key={index}>
            <ProjectCard project={item} />
          </Col>
        ))}
      </Row>
    </Fragment>
  );
};

export default Projects;
