import { Provider } from 'react-redux';

import Router from 'router/Router';
import store from 'redux/storeConfig';

import 'react-toastify/dist/ReactToastify.min.css';
import 'styles/App.scss';

const App = () => {
  return (
    <Provider store={store}>
      <Router />
    </Provider>
  );
};

export default App;
