import { Fragment } from 'react';

import CreateAccount from './CreateAccount';

const Mobile = () => {
  return (
    <Fragment>
      <h1>S-Computer app</h1>
      <small className='text-uppercase bold'>create account for the app</small>
      <CreateAccount />
    </Fragment>
  );
};

export default Mobile;
