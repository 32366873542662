import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';
import { createStore, applyMiddleware, compose } from 'redux';

import rootReducer from 'redux/reducers/rootReducer';

const middleware = [thunk, createDebounce()];
const enhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, {}, enhancer(applyMiddleware(...middleware)));

export default store;
