import { Fragment, useState } from 'react';
import { Edit } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Card, CardBody, CardTitle } from 'reactstrap';

import EditActiveHoursModal from './EditActiveHoursModal';
import EditConfigurationModal from './EditConfigurationModal';
import { handleUpdateDeviceConfig } from 'redux/actions/device';
import { configurationComOptions, dayIndex } from 'utils/context/boincContext';
import { styleConfig } from 'utils/context/styleConfig';
import { SHOW_MODAL } from 'redux/types';

export default ({ configurations }) => {
  const dispatch = useDispatch();
  const { _id } = useSelector((state) => state.view);
  const [modal, setModal] = useState(null);

  const handleUpdateDay = (payload) => {
    dispatch(
      handleUpdateDeviceConfig(
        {
          id: _id,
          time: [payload, ...configurations.time.filter((item) => item.day !== payload.day)],
        },
        () => setModal(null)
      )
    );
  };

  return (
    <Fragment>
      <div className='mb-2'>
        <Badge color='secondary' className='text-uppercase'>
          {Object.keys(configurationComOptions)[configurations.com]}
        </Badge>
        {configurations.default && (
          <Badge color='success' className='text-uppercase ms-2'>
            Suzuki recommended configuration enabled
          </Badge>
        )}

        <Edit
          size={styleConfig.iconSize}
          onClick={() => {
            dispatch({ type: SHOW_MODAL });
          }}
          className='ms-2 icon'
        />
        <EditConfigurationModal data={configurations} />
      </div>
      <div className='d-flex justify-content-between gap-2'>
        {configurations?.time
          ?.sort((a, b) => a.day - b.day)
          .map((item) => (
            <Card className='w-25'>
              <CardTitle className='d-flex justify-content-between align-items-center px-2 pt-2'>
                <span className='text-capitalize'>{dayIndex[item.day]}</span>
                <Badge className='text-uppercase p-1' color={item.active ? 'success' : 'danger'}>
                  <small>{item.active ? 'on' : 'off'}</small>
                </Badge>
              </CardTitle>
              <CardBody className='px-2'>
                <div className='d-flex justify-content-between align-items-center gap-2'>
                  <small>
                    <Badge color='secondary'>
                      {item.start} - {item.end}
                    </Badge>
                  </small>
                  <div>
                    <Edit
                      size={15}
                      onClick={() => {
                        setModal(item);
                      }}
                      className='icon'
                    />
                    {modal && (
                      <EditActiveHoursModal
                        data={modal}
                        close={() => setModal(null)}
                        submit={handleUpdateDay}
                      />
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          ))}
      </div>
    </Fragment>
  );
};
