import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import { handleUpdateDeviceConfig } from 'redux/actions/device';
import { configurationComOptions } from 'utils/context/boincContext';
import { HIDE_MODAL } from 'redux/types';

const EditConfigurationModal = ({ data }) => {
  const dispatch = useDispatch();
  const { _id } = useSelector((state) => state.view);
  const isOpen = useSelector((state) => state.app.modal);
  const [state, setState] = useState({
    default: data.default,
    com: data.com,
  });

  const onSubmit = () => {
    dispatch(
      handleUpdateDeviceConfig(
        {
          id: _id,
          default: state.default,
          com: state.com,
          time: data.time,
        },
        () => dispatch({ type: HIDE_MODAL })
      )
    );
  };

  const toggle = () => {
    dispatch({ type: HIDE_MODAL });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Edit configuration</ModalHeader>
      <ModalBody>
        <FormGroup className='d-flex justify-content-between align-content-center'>
          <Label className='align-self-end'>
            {' '}
            <small className='text-uppercase bold'>Suzuki default configurations</small>
          </Label>
          <Input
            name='default'
            placeholder='20'
            type='switch'
            checked={state.default}
            onChange={({ target }) =>
              setState({
                ...state,
                default: target.checked,
              })
            }
          />
        </FormGroup>
        <FormGroup className='d-flex justify-content-between align-content-center'>
          <Label className='align-self-end'>
            {' '}
            <small className='text-uppercase bold'>communication method</small>
          </Label>
          <Input
            name='com'
            type='select'
            disabled={state.default}
            value={state.com}
            defaultValue={state.com}
            onChange={({ target }) =>
              setState({
                ...state,
                com: parseInt(target.value),
              })
            }
            className='text-uppercase w-50'
          >
            {Object.keys(configurationComOptions).map((item, index) => (
              <option key={index} value={configurationComOptions[item]} className='text-uppercase'>
                {item}
              </option>
            ))}
          </Input>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>Cancel</Button>
        <Button onClick={onSubmit} className='btn-success'>
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditConfigurationModal;
