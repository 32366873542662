import axios from 'axios';

import { GET_COMMANDS, LOADED, LOADING } from 'redux/types';
import { actionCatchBlock } from 'utils/functions/actionCatchBlock';

export const handleGetCommands = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const { data } = await axios.get(`/commands/${id}?offline=true`);

    dispatch({
      type: GET_COMMANDS,
      data,
    });
  } catch (e) {
    actionCatchBlock(e, dispatch);
  }

  dispatch({ type: LOADED });
};
