import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Home from 'components/Home/Home';
import Header from 'components/Layout/Header';
import Auth from 'views/auth/Auth';
import Projects from 'views/projects/Projects';
import Devices from 'views/devices/Devices';
import Device from 'views/devices/Device';
import Mobile from 'views/mobile/Mobile';
import Loading from 'utils/components/Loading';

import { ProtectedRoute } from './ProtectedRoute';
import { handleGetUser } from 'redux/actions/auth';

const Router = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.token) {
      dispatch(handleGetUser());
    } else {
      return <Navigate to='/login' />;
    }
  }, [localStorage.token]);

  return (
    <BrowserRouter>
      <Loading />
      <ToastContainer />
      <Header />
      <section className='container w-100 h-100'>
        <Routes>
          <Route path='/login' element={<Auth />} />

          <Route path='/' element={<ProtectedRoute component={<Home />} />} />
          <Route path={'/projects'} element={<ProtectedRoute component={<Projects />} />} />
          <Route path={'/devices'} element={<ProtectedRoute component={<Devices />} />} />
          <Route path={'/devices/:id'} element={<ProtectedRoute component={<Device />} />} />
          <Route
            path={'/mobile'}
            element={<ProtectedRoute component={<ProtectedRoute component={<Mobile />} />} />}
          />
        </Routes>
      </section>
    </BrowserRouter>
  );
};
export default Router;
