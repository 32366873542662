import { combineReducers } from 'redux';

import authReducer from 'redux/reducers/authReducer';
import appReducer from 'redux/reducers/appReducer';
import projectReducer from 'redux/reducers/projectReducer';
import deviceReducer from 'redux/reducers/deviceReducer';
import viewReducer from 'redux/reducers/viewReducer';
import notificationReducer from 'redux/reducers/notificationReducer';

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  view: viewReducer,
  project: projectReducer,
  device: deviceReducer,
  notification: notificationReducer,
});

export default rootReducer;
