import DataTable from 'react-data-table-component';

export default ({ data }) => {
  const columns = [
    {
      name: '#',
      width: '50px',
      selector: (row, index) => <span>{index + 1}</span>,
    },
    {
      id: 'project',
      name: 'project',
      selector: ({ project }) => <span>{project?.name}</span>,
    },
    {
      id: 'usage',
      name: 'usage',
      selector: ({ usage }) => <span className='bold'>%{usage}</span>,
    },
  ];

  return <DataTable data={data} columns={columns} />;
};
