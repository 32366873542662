import { LogOut } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { handleLogout } from 'redux/actions/auth';
import { styleConfig } from 'utils/context/styleConfig';

export default () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);

  const logout = () => {
    dispatch(handleLogout());
  };

  return (
    <div className='bg-dark text-white'>
      <div className='container py-2 mb-2 d-flex justify-content-between'>
        <ul className='list-unstyled d-flex justify-content-start gap-5 m-0 p-0'>
          <li className='m-0'>
            <Link className='link' to='/'>
              <img src='sr_logo.jpg' style={{ width: '30px' }} />
            </Link>
          </li>
          <li className='m-0'>
            <Link className='link' to='/'>
              <small>Home</small>
            </Link>
          </li>
          <li className='m-0'>
            <Link className='link' to='/projects'>
              <small>Projects</small>
            </Link>
          </li>
          <li className='m-0'>
            <Link className='link' to='/devices'>
              <small>Devices</small>
            </Link>
          </li>
          <li className='m-0'>
            <Link className='link' to='/mobile'>
              <small>Mobile App</small>
            </Link>
          </li>
        </ul>

        <div className='d-flex justify-content-between align-items-center gap-3'>
          <small>{auth.email}</small>
          <span className='btn btn-danger btn-sm' onClick={logout}>
            <small>
              <LogOut size={styleConfig.iconSize} />
            </small>
          </span>
        </div>
      </div>
    </div>
  );
};
