import { toast } from 'react-toastify';

const { SHOW_NOTIFICATION, HIDE_NOTIFICATION } = require('redux/types');

const initialState = {
  toast: false,
  message: '',
  error: false,
};

const notificationReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SHOW_NOTIFICATION:
      data.error ? toast.error(data.message) : toast.success(data.message);

      return {
        ...state,
        toast: true,
        message: data.message,
      };
    case HIDE_NOTIFICATION:
      return {
        ...state,
        toast: false,
        message: '',
      };

    default:
      return state;
  }
};

export default notificationReducer;
