import axios from 'axios';

import { endpointList } from 'utils/context/endpoints';
import { GET_USER, LOGIN, LOGOUT, SHOW_NOTIFICATION } from 'redux/types';
import { LOGIN_SUCCESS_MESSAGE, LOGOUT_SUCCESS_MESSAGE } from 'utils/context/messages';
import { actionCatchBlock } from 'utils/functions/actionCatchBlock';

export const handleGetUser = () => async (dispatch) => {
  try {
    const { data } = await axios.get(endpointList.auth);

    dispatch({
      type: GET_USER,
      data,
    });
  } catch (e) {
    actionCatchBlock(e, dispatch);

    dispatch({
      type: LOGOUT,
    });
  }
};

export const handleLogin = (payload, callback) => async (dispatch) => {
  try {
    const { data } = await axios.post(endpointList.login, payload);

    callback();

    dispatch({
      type: LOGIN,
      data,
    });

    dispatch({
      type: SHOW_NOTIFICATION,
      data: {
        message: LOGIN_SUCCESS_MESSAGE,
      },
    });
  } catch (e) {
    actionCatchBlock(e, dispatch);
  }
};

export const handleLogout = () => async (dispatch) => {
  dispatch({ type: LOGOUT });

  dispatch({ type: SHOW_NOTIFICATION, data: { message: LOGOUT_SUCCESS_MESSAGE, error: true } });
};
