import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { HIDE_CONFIRMATION } from 'redux/types';

export const ConfirmationModal = ({ isOpen, callback, title, bodyMessage }) => {
  const dispatch = useDispatch();

  const toggle = () => {
    dispatch({
      type: HIDE_CONFIRMATION,
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <p>{bodyMessage}</p>
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>Cancel</Button>
        <Button onClick={callback} className='btn-danger'>
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};
