import axios from 'axios';

import {
  CONTROL_CLIENT,
  CONTROL_DEVICE_PROJECT,
  GET_DEVICE,
  GET_DEVICES,
  GET_DEVICE_CONFIG,
  LOADED,
  LOADING,
  SHOW_NOTIFICATION,
} from 'redux/types';
import { endpointList } from 'utils/context/endpoints';
import { actionCatchBlock } from 'utils/functions/actionCatchBlock';

export const handleGetDevices = () => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const { data } = await axios.get('/device');

    dispatch({
      type: GET_DEVICES,
      data,
    });
  } catch (e) {
    actionCatchBlock(e, dispatch);
  }

  dispatch({ type: LOADED });
};

export const handleGetDevice = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const { data } = await axios.get(`/device/${id}`);

    dispatch({
      type: GET_DEVICE,
      data,
    });
  } catch (e) {
    actionCatchBlock(e, dispatch);
  }

  dispatch({ type: LOADED });
};

export const handleGetDeviceConfig = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const { data } = await axios.get(`${endpointList.device}/${id}/config`);

    dispatch({
      type: GET_DEVICE_CONFIG,
      data,
    });
  } catch (e) {
    actionCatchBlock(e, dispatch);
  }

  dispatch({ type: LOADED });
};

export const handleUpdateDeviceConfig = (payload, callback) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const { data } = await axios.patch(`${endpointList.device}/${payload.id}/config`, payload);

    dispatch({
      type: SHOW_NOTIFICATION,
      data: {
        message: data.msg,
      },
    });

    dispatch(handleGetDeviceConfig(payload.id));

    callback();
  } catch (e) {
    actionCatchBlock(e, dispatch);
  }

  dispatch({ type: LOADED });
};

export const handleUpdateDevice = (payload, callback) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const { data } = await axios.patch(`${endpointList.device}/${payload.id}`, payload);

    dispatch({
      type: SHOW_NOTIFICATION,
      data: {
        message: data.msg,
      },
    });

    dispatch(handleGetDevice(payload.id));

    callback();
  } catch (e) {
    actionCatchBlock(e, dispatch);
  }

  dispatch({ type: LOADED });
};

export const handleControlClient =
  ({ payload, callback }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const { data } = await axios.post(endpointList.controlClient, payload);

      dispatch({
        type: CONTROL_CLIENT,
        data,
      });

      dispatch({
        type: SHOW_NOTIFICATION,
        data: {
          message: data.msg,
        },
      });

      callback();
    } catch (e) {
      actionCatchBlock(e, dispatch);
    }

    dispatch({ type: LOADED });
  };

export const handleControlDeviceProject =
  ({ payload, callback }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const { data } = await axios.post(endpointList.controlDeviceProject, payload);

      dispatch({
        type: CONTROL_DEVICE_PROJECT,
        data,
      });

      dispatch({
        type: SHOW_NOTIFICATION,
        data: {
          message: data.msg,
        },
      });

      callback();
    } catch (e) {
      actionCatchBlock(e, dispatch);
    }

    dispatch({ type: LOADED });
  };
