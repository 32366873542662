import axios from 'axios';

import { LOADED, LOADING, SHOW_NOTIFICATION } from 'redux/types';
import { actionCatchBlock } from 'utils/functions/actionCatchBlock';

export const handleCreateAccount = (payload, callback) => async (dispatch) => {
  dispatch({
    type: LOADING,
  });

  try {
    const { data } = await axios.post('/mobile/register', payload);

    dispatch({
      type: SHOW_NOTIFICATION,
      data: {
        message: data.msg,
      },
    });

    callback();
  } catch (e) {
    actionCatchBlock(e, dispatch);
  }

  dispatch({
    type: LOADED,
  });
};
