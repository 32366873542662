import { useSelector } from 'react-redux';

const Loading = () => {
  const { loading } = useSelector((state) => state.app);

  return loading ? (
    <div className='loading blur h-100 w-100 d-flex justify-content-center align-items-center bg-white '>
      <img src='/spinner.gif' />
    </div>
  ) : (
    <div></div>
  );
};

export default Loading;
