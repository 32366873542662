export const endpointList = {
  auth: '/auth',
  login: '/auth/login',

  project: '/project',

  device: '/device',
  controlClient: '/device/boinc',
  controlDeviceProject: '/device/project',
};
