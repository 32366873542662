export const LOADING = 'LOADING';
export const LOADED = 'LOADED';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const GET_USER = 'GET_USER';

export const GET_PROJECTS = 'GET_PROJECTS';

export const GET_DEVICES = 'GET_DEVICES';
export const GET_DEVICE = 'GET_DEVICE';
export const GET_DEVICE_CONFIG = 'GET_DEVICE_CONFIG';
export const UPDATE_DEVICE = 'UPDATE_DEVICE';
export const CONTROL_CLIENT = 'CONTROL_CLIENT';
export const CONTROL_DEVICE_PROJECT = 'CONTROL_DEVICE_PROJECT';

export const GET_COMMANDS = 'GET_COMMANDS';

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const SHOW_CONFIRMATION = 'SHOW_CONFIRMATION';
export const HIDE_CONFIRMATION = 'HIDE_CONFIRMATION';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const UPDATE_VIEW = 'UPDATE_VIEW';
export const CLEAR_VIEW = 'CLEAR_VIEW';

export const SOCKET_ON = 'SOCKET_ON';
export const SOCKET_OFF = 'SOCKET_OFF';
