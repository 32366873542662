import { Fragment, useEffect, useState } from 'react';
import { CheckCircle, XCircle } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Card, CardTitle, Col, Row } from 'reactstrap';
import Select from 'react-select';

import DeviceTasks from 'components/Device/DeviceTasks';
import DeviceConfig from 'components/Device/DeviceConfig';
import AsyncComponent from 'utils/components/AsyncComponent';
import {
  handleControlClient,
  handleControlDeviceProject,
  handleGetDevice,
  handleGetDeviceConfig,
} from 'redux/actions/device';
import { styleConfig } from 'utils/context/styleConfig';
import { handleGetProjects } from 'redux/actions/project';
import { boincClientActions, boincProjectActions } from 'utils/context/boincContext';
import { handleGetCommands } from 'redux/actions/commands';
import { handleSocketOff, handleSocketOn } from 'redux/actions/socket';
import { CLEAR_VIEW } from 'redux/types';

export default () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const data = useSelector((state) => state.view);
  const { projects } = useSelector((state) => state.project);
  const [controlClientState, setControlClientState] = useState({});
  const [controlProjectState, setControlProjectState] = useState({});

  const controlDeviceProject = () => {
    const payload = {
      imei: data.imei,
      project: controlProjectState.project.value,
      action: controlProjectState.action.value,
    };

    dispatch(
      handleControlDeviceProject({
        payload,
        callback: () => {
          setControlProjectState({});
          dispatch(handleGetDevice(id));
        },
      })
    );
  };

  const controlClient = () => {
    const payload = {
      imei: data.imei,
      action: controlClientState.action.value,
    };

    dispatch(
      handleControlClient({
        payload,
        callback: () => {
          setControlClientState({});
          dispatch(handleGetDevice(id));
        },
      })
    );
  };

  useEffect(() => {
    dispatch(handleSocketOn({ imei: data?.imei }));
    dispatch(handleGetDevice(id));
    dispatch(handleGetDeviceConfig(id));
    dispatch(handleGetCommands(id));
    dispatch(handleGetProjects());

    return () => {
      dispatch(handleSocketOff());
      dispatch({ type: CLEAR_VIEW });
    };
  }, []);

  projects?.map((item) => {
    item.label = item.name;
    item.value = item._id;
  });

  const clientActions = Object.keys(boincClientActions).map((item) => ({
    value: boincClientActions[item],
    label: item.toUpperCase(),
  }));
  const projectActions = Object.keys(boincProjectActions).map((item) => ({
    value: boincProjectActions[item],
    label: item.toUpperCase(),
  }));

  const Component = () => (
    <Fragment>
      <h1>Device {data.imei}</h1>
      <Row>
        <Col xs='12'>
          <Row>
            <Col xs='9' className='d-flex flex-column'>
              <small className='text-uppercase bold'>s-computer account</small>
              <p>{data.email}</p>
            </Col>
            <Col xs='3' className='d-flex justify-content-end'>
              <small
                className={`text-uppercase bold ${data.status ? 'text-success' : 'text-danger'}`}
              >
                <span>
                  {data.status ? (
                    <CheckCircle size={styleConfig.iconSize} />
                  ) : (
                    <XCircle size={styleConfig.iconSize} />
                  )}
                </span>{' '}
                <span>{data.status ? 'online' : 'offline'}</span>
              </small>
            </Col>
          </Row>
        </Col>
        <Col xs='12'>
          <small className='text-uppercase bold'>
            control boinc client - currently is{' '}
            <span
              className={
                data.boinc?.status === boincClientActions.open
                  ? 'text-success'
                  : data.boinc?.status === boincClientActions.close
                  ? 'text-danger'
                  : 'text-warning'
              }
            >
              {Object.keys(boincClientActions).find(
                (item) => boincClientActions[item] === data.boinc?.status
              )}
            </span>{' '}
          </small>
          <Row className='mt-2'>
            <Col xs='4'>
              <Select
                className='basic-single'
                classNamePrefix='select'
                isDisabled={false}
                isClearable={true}
                isSearchable={true}
                name='name'
                options={clientActions}
                placeholder='Select action'
                value={controlClientState.action}
                onChange={(action) => setControlClientState({ ...controlClientState, action })}
              />
            </Col>
            <Col className='d-flex justify-content-end'>
              <Button
                disabled={!controlClientState.action}
                onClick={controlClient}
                className='btn-success'
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs='12' className='mt-2'>
          <small className='text-uppercase bold'>control projects</small>
          <Row className='mt-2'>
            <Col>
              <Select
                className='basic-single'
                classNamePrefix='select'
                // defaultValue={projects[0]}
                isDisabled={false}
                isLoading={!projects}
                isClearable={true}
                isSearchable={true}
                name='name'
                options={projects}
                placeholder='Select project'
                value={controlProjectState.project}
                onChange={(project) => setControlProjectState({ ...controlProjectState, project })}
              />
            </Col>
            <Col>
              <Select
                className='basic-single'
                classNamePrefix='select'
                // defaultValue={projects[0]}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isSearchable={true}
                name='name'
                options={projectActions}
                placeholder='Select action'
                value={controlProjectState.action}
                onChange={(action) => {
                  setControlProjectState({ ...controlProjectState, action });
                }}
              />
            </Col>
            <Col className='d-flex justify-content-end'>
              <Button
                disabled={!controlProjectState.project || !controlProjectState.action}
                onClick={controlDeviceProject}
                className='btn-success'
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs='12' className='mt-2'>
          <small className='text-uppercase bold'>projects on device</small>
          <DeviceTasks data={data.tasks} />
        </Col>
        {data.commands?.commands?.length > 0 && (
          <Col xs='12' className='mt-2'>
            <small className='text-uppercase bold text-warning'>pending commands</small>
            <div>
              {data.commands?.commands &&
                data.commands?.commands?.map((item) =>
                  item.type === 1 ? (
                    <Card className='p-2 mb-2' key={item._id}>
                      <CardTitle>
                        <span className='text-uppercase'>
                          {Object.keys(boincProjectActions).find(
                            (key) => boincProjectActions[key] === item.content?.action
                          )}
                        </span>{' '}
                        - {projects?.find((p) => p._id === item.content?.projectId)?.name}
                      </CardTitle>
                    </Card>
                  ) : (
                    <Card className='p-2 my-2' key={item._id}>
                      <CardTitle>
                        <span className='text-uppercase'>
                          {Object.keys(boincClientActions).find(
                            (act) => boincClientActions[act] === item.content?.boinc?.action
                          )}
                        </span>{' '}
                        - BOINC Client
                      </CardTitle>
                    </Card>
                  )
                )}
            </div>
          </Col>
        )}
        {data?.configurations && (
          <Col xs='12' className='mt-2'>
            <small className='text-uppercase bold'>device configurations</small>
            <DeviceConfig configurations={data?.configurations} />
          </Col>
        )}
      </Row>
    </Fragment>
  );

  return <AsyncComponent Component={Component} />;
};
