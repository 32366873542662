import axios from 'axios';

import { endpointList } from 'utils/context/endpoints';
import { GET_PROJECTS, LOADED, LOADING, SHOW_NOTIFICATION } from 'redux/types';
import { actionCatchBlock } from 'utils/functions/actionCatchBlock';

export const handleGetProjects = () => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const { data } = await axios.get(endpointList.project);

    dispatch({
      type: GET_PROJECTS,
      data,
    });
  } catch (e) {
    actionCatchBlock(e, dispatch);
  }

  dispatch({ type: LOADED });
};

export const handleCreateProject = (payload, callback) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const { data } = await axios.post(endpointList.project, payload);

    dispatch({
      type: SHOW_NOTIFICATION,
      data: {
        message: data.msg,
      },
    });

    dispatch(handleGetProjects());

    callback();
  } catch (e) {
    actionCatchBlock(e, dispatch);
  }

  dispatch({ type: LOADED });
};

export const handleUpdateProject = (payload, callback) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const { data } = await axios.patch(endpointList.project, payload);

    dispatch({
      type: SHOW_NOTIFICATION,
      data: {
        message: data.msg,
      },
    });

    dispatch(handleGetProjects());

    callback();
  } catch (e) {
    actionCatchBlock(e, dispatch);
  }

  dispatch({ type: LOADED });
};

export const handleDeleteProject = (id, callback) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const { data } = await axios.delete(endpointList.project + `/${id}`);

    dispatch({
      type: SHOW_NOTIFICATION,
      data: {
        message: data.msg,
      },
    });

    dispatch(handleGetProjects());

    callback();
  } catch (e) {
    actionCatchBlock(e, dispatch);
  }

  dispatch({ type: LOADED });
};
