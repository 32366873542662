export const boincProjectActions = {
  add: 0,
  start: 1,
  stop: 2,
  remove: 3,
};

export const boincClientActions = {
  open: 0,
  close: 1,
  drop: 2,
};

export const configurationComOptions = {
  wifi: 0,
  lte: 1,
  wifi_lte: 2,
};

export const dayIndex = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];
