import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';

import Login from 'components/Auth/Login';

export default () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(-1);
    }
  }, [isAuthenticated]);

  return (
    <div className='d-flex h-100 justify-content-center align-items-center'>
      <Card>
        <CardHeader>
          <h3 className='text-center'>Welcome to BOINC account manager</h3>
        </CardHeader>
        <CardBody>
          <Login />
        </CardBody>
      </Card>
    </div>
  );
};
