import { useState } from 'react';
import {
  Badge,
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import { dayIndex } from 'utils/context/boincContext';

const EditActiveHoursModal = ({ data, close, submit }) => {
  const [state, setState] = useState({
    active: data.active,
    startHour: data.start.slice(0, 2),
    startMinute: data.start.slice(3),
    endHour: data.end.slice(0, 2),
    endMinute: data.end.slice(3),
  });

  const onSubmit = () => {
    const doubleDigit = (number) => (number.toString().length < 2 ? `0${number}` : number);

    const payload = {
      day: data.day,
      active: state.active,
      start: `${doubleDigit(state.startHour)}:${doubleDigit(state.startMinute)}`,
      end: `${doubleDigit(state.endHour)}:${doubleDigit(state.endMinute)}`,
    };

    submit(payload);
  };

  return (
    <Modal size='sm' isOpen={data} toggle={close} centered>
      <ModalHeader toggle={close}>
        Edit{' '}
        <Badge className='text-uppercase' color='secondary'>
          {dayIndex[data.day]}
        </Badge>
      </ModalHeader>
      <ModalBody>
        <FormGroup className='d-flex justify-content-between align-content-center'>
          <Label className='align-self-end'>
            {' '}
            <small className='text-uppercase bold'>Active</small>
          </Label>
          <Input
            name='active'
            placeholder='20'
            type='switch'
            checked={state.active}
            onChange={({ target }) =>
              setState({
                ...state,
                active: target.checked,
              })
            }
          />
        </FormGroup>

        <FormGroup className='d-flex justify-content-between align-content-center'>
          <Label className='align-self-end'>
            {' '}
            <small className='text-uppercase bold'>start time</small>
          </Label>
          <div className='d-flex justify-content-between gap-2 w-50'>
            <Input
              name='start-hour'
              placeholder='17'
              type='number'
              min={0}
              max={24}
              value={state.startHour}
              onChange={({ target }) =>
                setState({
                  ...state,
                  startHour: target.value,
                })
              }
            />
            <Input
              name='start-minute'
              placeholder='30'
              type='number'
              min={0}
              max={60}
              value={state.startMinute}
              onChange={({ target }) =>
                setState({
                  ...state,
                  startMinute: target.value,
                })
              }
            />
          </div>
        </FormGroup>

        <FormGroup className='d-flex justify-content-between align-content-center'>
          <Label className='align-self-end'>
            {' '}
            <small className='text-uppercase bold'>end time</small>
          </Label>
          <div className='d-flex justify-content-between gap-2 w-50'>
            <Input
              name='end-hour'
              placeholder='20'
              type='number'
              min={0}
              max={24}
              value={state.endHour}
              onChange={({ target }) =>
                setState({
                  ...state,
                  endHour: target.value,
                })
              }
            />
            <Input
              name='end-minute'
              placeholder='30'
              type='number'
              min={0}
              max={60}
              value={state.endMinute}
              onChange={({ target }) =>
                setState({
                  ...state,
                  endMinute: target.value,
                })
              }
            />
          </div>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button onClick={close}>Cancel</Button>
        <Button onClick={onSubmit} className='btn-success'>
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditActiveHoursModal;
