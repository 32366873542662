import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';

import { handleCreateProject } from 'redux/actions/project';
import { HIDE_MODAL } from 'redux/types';

export default () => {
  const dispatch = useDispatch();
  const { modal } = useSelector((state) => state.app);
  const [state, setState] = useState({});

  const toggle = () => {
    dispatch({
      type: HIDE_MODAL,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(handleCreateProject(state, toggle));
  };

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add project</ModalHeader>
      <ModalBody>
        <Form onSubmit={onSubmit}>
          <FormGroup>
            <Label>
              {' '}
              <small className='text-uppercase bold'>name</small>
            </Label>
            <Input
              name='name'
              placeholder='ClimateChange'
              value={state.name}
              onChange={({ target }) =>
                setState({
                  ...state,
                  name: target.value,
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label>
              {' '}
              <small className='text-uppercase bold'>url</small>
            </Label>
            <Input
              name='url'
              placeholder='https://...'
              value={state.url}
              onChange={({ target }) =>
                setState({
                  ...state,
                  url: target.value,
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label>
              <small className='text-uppercase bold'>email</small>
            </Label>
            <Input
              name='email'
              placeholder='test@soarrobotics.com'
              value={state.email}
              onChange={({ target }) =>
                setState({
                  ...state,
                  email: target.value,
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label>
              <small className='text-uppercase bold'>password</small>
            </Label>
            <Input
              name='password'
              placeholder='************'
              value={state.password}
              onChange={({ target }) =>
                setState({
                  ...state,
                  password: target.value,
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label>
              <small className='text-uppercase bold'>key</small>
            </Label>
            <Input
              name='key'
              placeholder='************'
              value={state.key}
              onChange={({ target }) =>
                setState({
                  ...state,
                  key: target.value,
                })
              }
            />
          </FormGroup>

          <Button className='w-100 btn-success' type='submit'>
            Submit
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};
