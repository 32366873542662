import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { handleCreateAccount } from 'redux/actions/mobile';
import { SHOW_NOTIFICATION } from 'redux/types';

export default () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({});

  const onSubmit = (e) => {
    e.preventDefault();

    if (state.password !== state.passwordRepeat) {
      dispatch({
        type: SHOW_NOTIFICATION,
        data: {
          message: 'Passwords do not match',
          error: true,
        },
      });
    } else {
      dispatch(handleCreateAccount(state, handleClearForm));
    }
  };

  const handleClearForm = () => {
    setState({});
  };

  return (
    <Form onSubmit={onSubmit}>
      <Row>
        <Col xs='6'>
          <FormGroup>
            <Label className='text-uppercase'>imei</Label>
            <Input
              name='imei'
              value={state.imei}
              onChange={({ target }) => {
                setState({ ...state, imei: target.value });
              }}
            />
          </FormGroup>
        </Col>
        <Col xs='6'>
          <FormGroup>
            <Label>Email</Label>
            <Input
              type='email'
              name='email'
              value={state.email}
              onChange={({ target }) => {
                setState({ ...state, email: target.value });
              }}
            />
          </FormGroup>
        </Col>
        <Col xs='6'>
          <FormGroup>
            <Label>Password</Label>
            <Input
              type='password'
              name='password'
              value={state.password}
              onChange={({ target }) => {
                setState({ ...state, password: target.value });
              }}
            />
          </FormGroup>
        </Col>
        <Col xs='6'>
          <FormGroup>
            <Label>Password repeat</Label>
            <Input
              type='password'
              name='passwordRepeat'
              value={state.passwordRepeat}
              onChange={({ target }) => {
                setState({ ...state, passwordRepeat: target.value });
              }}
            />
          </FormGroup>
        </Col>
        <Col xs='12' className='d-flex justify-content-end'>
          <Button
            disabled={!state.imei || !state.email || !state.password || !state.passwordRepeat}
            className='btn-success'
          >
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
