import axios from 'axios';
import config from 'config/config';

const { server } = config[config.current_env].url;

const setAuthToken = () => {
  const { token } = localStorage;

  axios.defaults.baseURL = server;

  if (token) {
    axios.defaults.headers.common['authorization'] = token;
  } else {
    delete axios.defaults.headers.common['authorization'];
  }
};

export default setAuthToken;
