import { SHOW_NOTIFICATION } from 'redux/types';

export const actionCatchBlock = (e, dispatch) => {
  const errors = e.response?.data?.errors || [{ msg: e.message }];

  errors.map((item) => {
    dispatch({
      type: SHOW_NOTIFICATION,
      data: { message: item.msg, error: true },
    });
  });
};
