import {
  HIDE_CONFIRMATION,
  HIDE_MODAL,
  LOADED,
  LOADING,
  SHOW_CONFIRMATION,
  SHOW_MODAL,
} from 'redux/types';

const initialState = {
  loading: false,
  modal: false,
  confirmation: false,
};

const appReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOADED:
      return {
        ...state,
        loading: false,
      };
    case SHOW_MODAL:
      return {
        ...state,
        modal: true,
      };
    case HIDE_MODAL:
      return {
        ...state,
        modal: false,
      };
    case SHOW_CONFIRMATION:
      return {
        ...state,
        confirmation: true,
      };
    case HIDE_CONFIRMATION:
      return {
        ...state,
        confirmation: false,
      };

    default:
      return state;
  }
};

export default appReducer;
