import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { Trash2 } from 'react-feather';

import { styleConfig } from 'utils/context/styleConfig';
import { HIDE_CONFIRMATION, SHOW_CONFIRMATION } from 'redux/types';
import { ConfirmationModal } from 'utils/components/ConfirmationModal';
import { handleDeleteProject } from 'redux/actions/project';

export default ({ project }) => {
  const dispatch = useDispatch();
  const { confirmation } = useSelector((state) => state.app);

  const showConfirmation = () => {
    dispatch({ type: SHOW_CONFIRMATION });
  };
  const hideConfirmation = () => {
    dispatch({ type: HIDE_CONFIRMATION });
  };

  const deleteProject = () => {
    dispatch(handleDeleteProject(project._id, hideConfirmation));
  };

  return (
    <Card>
      {confirmation && (
        <ConfirmationModal
          isOpen={confirmation}
          title='Delete project'
          bodyMessage='Are you sure you want to delete this project?'
          callback={deleteProject}
        />
      )}
      <CardHeader className='d-flex justify-content-between'>
        <span>{project.name}</span>
        <div className='btn p-0' onClick={showConfirmation}>
          <Trash2 size={styleConfig.iconSize} className='text-danger' />
        </div>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs='12'>
            <div className='d-flex flex-column'>
              <small className='text-uppercase bold'>url</small>
              <small>{project.url}</small>
            </div>
          </Col>
          <Col xs='12'>
            <div className='d-flex flex-column'>
              <small className='text-uppercase bold'>email</small>
              <small>{project.email}</small>
            </div>
          </Col>
          <Col xs='12'>
            <div className='d-flex flex-column'>
              <small className='text-uppercase bold'>key</small>
              <small>{project.key}</small>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
