import { Col, Row } from 'reactstrap';

export default ({}) => (
  <div>
    <Row>
      <Col xs='9'>
        <h1 className='text-uppercase'>account manager</h1>
      </Col>
      <Col xs='3' className='d-flex justify-content-end'>
        <div className='text-right'>
          <img src='suzuki_logo.jpg' style={{ width: '50px' }} />
          <img src='boinc_logo.png' style={{ width: '50px' }} />
          <img src='sr_logo_transparent.png' style={{ width: '50px' }} />
        </div>
      </Col>
    </Row>
  </div>
);
